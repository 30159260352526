<script lang="ts" setup>
import './app-beta.styl'

const props = defineProps<{
  url: string
  title?: string
  note?: string
}>()
</script>

<template>
  <teleport to="body">
    <div class="app-beta">
      <div class="app-beta-inner">
        <a :href="url" target="_blank">
          <span class="app-beta-space" />
          <span class="app-beta-beta">
            {{ props.title ?? 'ALPHA' }}
          </span>
          <span class="app-beta-note">
            {{ props.note ?? 'Private Preview' }}
          </span>
        </a>
      </div>
    </div>
    <div class="app-beta-status" />
  </teleport>
</template>
